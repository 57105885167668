import { IonBadge } from '@ionic/react';

import { productImageUrl } from '../configs/app';
import { getBadgeColor } from '../services/account/invoices.service';
import Invoice from '../types/shop/invoice.type';
import './InvoiceBody.scss';
import HtmlParser from './HtmlParser';
import QuickView from './Products/QuickView';

const BDT = new Intl.NumberFormat('en-IN');
type Props = {
    data: Invoice
}
const InvoiceBody: React.FC<Props> = ({ data }) => {
    const invoice = data
    function getGrandTotal() {
        let total = 0
        if (invoice.shipping.shipping_status == "Paid") {
            total = parseFloat(invoice.total)
        } else {
            total = (parseFloat(invoice.total) + parseFloat(invoice.shipping.shipping_cost))
        }
        if (!invoice.isReseller && parseFloat(invoice.discount) > 0) {
            total -= parseFloat(invoice.discount)
        }
        return total
    }
    return (
        <div id="invoice">
            <h1 className='text-center'>Invoice</h1>
            <table>
                <tbody>
                    <tr>
                        <th className='text-start' colSpan={3}>Invoice #{invoice.id.toString().padStart(6, "0")}</th>
                        <th className='text-end'>{new Date(Date.parse(invoice.created_at)).toDateString()}</th>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr>
                        {invoice.resellerShopDetails ? <td colSpan={2}>
                            <HtmlParser html={invoice.resellerShopDetails} />
                        </td> : <td colSpan={2}>
                            <b>{invoice.company.title}</b> <br />
                            {invoice.company.adress_1} <br />
                            {invoice.company.adress_2} <br />
                            Phone: {invoice.company.phone} <br />
                            Email : {invoice.company.email}
                        </td>}
                        <td colSpan={2}>
                            <strong>Shipping Information</strong> <br />
                            {invoice.shipping.name} <br />
                            {invoice.shipping.address} <br />
                            Phone: {invoice.shipping.phone}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table className='items'>
                <thead>
                    <tr>
                        <th colSpan={2}>Name/Description</th>
                        <th>Qty.</th>
                        <th title={"Unit Price"}>{"MRP"}</th>
                        <th>{"Amount"}</th>
                    </tr>
                </thead>
                <tbody>

                    {invoice.order_details.map((item, index) => {
                        const unitPrice = item.product_price
                        return <tr key={index}>
                            <td className='has-img'><img src={productImageUrl + item.product_image} alt="" /></td>
                            <td><QuickView title={item.product_name} productId={parseInt(item.product_id)}><>{item.product_name}</></QuickView></td>
                            <td className='text-center'>{item.product_quantity}</td>
                            <td className='text-end'>{BDT.format(unitPrice)}/=</td>
                            <td className='text-end'>{BDT.format(item.product_quantity * unitPrice)}/=</td>
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td rowSpan={5} colSpan={2} className='info'>
                            <div className='row'>
                                <div className='title'>Payment Type</div>
                                <div className='data'>{invoice.payment.type}</div>
                            </div>
                            <div className='row'>
                                <div className='title'>Payment Status</div>
                                <div className='data'>
                                    {invoice.payment.status}
                                </div>
                            </div>
                            {invoice.shipping.shipping_status == "Paid" ?
                                <div className='row'>
                                    <div className='title'>
                                        Shipping Cost

                                    </div>
                                    <div className='data'>
                                        {BDT.format(parseFloat(invoice.shipping.shipping_cost))}/= ({invoice.shipping.shipping_cost_reason}) (Paid)
                                    </div>
                                </div> : null}
                            {invoice.delivery_vendor && <div className='row'>
                                <div className='title'>Delivery Vendor</div>
                                <div className='data'>{invoice.delivery_vendor}</div>
                            </div>}
                            {invoice.tracking_id && <div className='row'>
                                <div className='title'>Tracking ID</div>
                                <div className='data'>{invoice.tracking_id}</div>
                            </div>}
                            {invoice.tracking_url && <div className='row'>
                                <div className='title'>Tracking Link</div>
                                <div className='data'>
                                    <a className='print-hidden' href={invoice.tracking_url} target='_blank'>Track Order</a>
                                    <a className='screen-hidden' href={invoice.tracking_url} target='_blank'>{invoice.tracking_url}</a>
                                </div>
                            </div>}
                            <div className='row'>
                                <div className='title'>Order Status</div>
                                <div className='data'>
                                    <IonBadge color={getBadgeColor(invoice.status)}>{invoice.status}</IonBadge>
                                    <span className='screen-hidden'>{invoice.status}</span>
                                </div>
                            </div>
                        </td>
                        <th colSpan={2}>Sub Total</th>
                        <td className='text-end'>{BDT.format(parseFloat(invoice.total))}/=</td>
                    </tr>
                    {invoice.shipping.shipping_status !== "Paid" && <tr>
                        <th colSpan={2}>Shipping Cost <p className='shipping-reason'>{invoice.shipping.shipping_cost_reason}</p></th>
                        <td className='text-end'>{BDT.format(parseFloat(invoice.shipping.shipping_cost))}/=</td>
                    </tr>}
                    {!invoice.isReseller && parseFloat(invoice.discount) > 0 && <tr>
                        <th colSpan={2}>Discount</th>
                        <td className='text-end'>(-) {BDT.format(parseFloat(invoice.discount))}/=</td>
                    </tr>}
                    <tr>
                        <th colSpan={2}>{invoice.payment.status === "Pending" ? "Total Payable" : "Total Paid"}</th>
                        <td className='text-end'>{BDT.format(getGrandTotal())}/=</td>
                    </tr>
                </tfoot>
            </table>
            <div className='print-hidden space'></div>
        </div>
    );
};

export default InvoiceBody;
