import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { useHistory, useLocation, useParams } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { deleteConversation, fetchConversation, sendImage, sendMessage, unseenCount, updateTitle } from '../../services/account/message.service';
import Conversation, { ChatMessage, FCMPayload } from '../../types/conversation.type';
import { baseUrl } from '../../configs/app';
import { MessageList, Input, MessageType } from 'react-chat-elements'
import './ConversationPage.scss'
// RCE CSS
import 'react-chat-elements/dist/main.css';
import { arrowBack, closeCircle, image, paperPlane, pencilOutline, saveOutline, trash } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { messaging } from '../../configs/firebase.config';
import { onMessage } from 'firebase/messaging';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';

type ScreenData = {
    messages: ChatMessage[]
    conversation: Conversation
};
type ModalData = {
    data: ScreenData
    setData: React.Dispatch<React.SetStateAction<ScreenData | null>>
};
type DeleteModalData = {
    data: ScreenData
};
const DeleteConfirmationModal: React.FC<DeleteModalData> = ({ data }) => {
    const modalRef = useRef<HTMLIonModalElement>(null)
    const inputRef = useRef<HTMLIonInputElement>(null)
    const history = useHistory()
    const [disabled, setDisabled] = useState(false)
    function onDelete() {
        if (!data.conversation.id) {
            console.error("Paremeter Missing")
            return
        }
        setDisabled(true)
        deleteConversation(data.conversation.id).then((res) => {
            history.goBack()
        }).finally(() => {
            setDisabled(false)
        })
    }
    return <>
        <IonButton fill='clear' color='danger' onClick={() => {
            modalRef.current?.present().then(() => {
                if (inputRef.current) {
                    inputRef.current.value = data.conversation.name
                }
            })


        }}>
            <FontAwesomeIcon icon={faTrash} />
        </IonButton>
        <IonModal ref={modalRef}
            breakpoints={[0, 0.25, 0.5]}
            initialBreakpoint={0.25}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Delete Confirmation</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <IonRow>
                    <IonCol>
                        <IonButton fill='outline' color='danger' expand="block" onClick={() => modalRef.current?.dismiss()}>
                            <IonIcon slot="start" icon={closeCircle} /> Close
                        </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton disabled={disabled} onClick={onDelete} color='success' expand="block">
                            <IonIcon slot="start" icon={trash} /> Delete
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>
    </>
};
const UpdateTitleModal: React.FC<ModalData> = ({ data, setData }) => {
    const modalRef = useRef<HTMLIonModalElement>(null)
    const inputRef = useRef<HTMLIonInputElement>(null)
    const [disabled, setDisabled] = useState(false)
    function onUpdate() {
        if (!inputRef.current || !inputRef.current.value || !data.conversation.id) {
            console.error("Paremeter Missing")
            return
        }
        setDisabled(true)
        updateTitle(data.conversation.id, inputRef.current.value.toString()).then((res) => {
            modalRef.current?.dismiss()
            setData(res.data)
        }).finally(() => {
            setDisabled(false)
        })
    }
    return <>
        <IonButton fill='clear' onClick={() => {
            modalRef.current?.present().then(() => {
                if (inputRef.current) {
                    inputRef.current.value = data.conversation.name
                }
            })


        }}>
            <FontAwesomeIcon icon={faEdit} />
        </IonButton>
        <IonModal ref={modalRef}
            breakpoints={[0, 0.25, 0.5]}
            initialBreakpoint={0.25}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Update Title</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <IonList>
                    <IonItem>
                        <IonLabel slot='start'>Subject</IonLabel>
                        <IonInput ref={inputRef} />
                    </IonItem>
                </IonList>
                <IonRow>
                    <IonCol>
                        <IonButton fill='outline' color='danger' expand="block" onClick={() => modalRef.current?.dismiss()}>
                            <IonIcon slot="start" icon={closeCircle} /> Close
                        </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton disabled={disabled} onClick={onUpdate} color='success' expand="block">
                            <IonIcon slot="start" icon={saveOutline} /> Update
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonModal>
    </>
};
const Body: React.FC = () => {
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const { id } = useParams() as { id: string }
    const history = useHistory()
    const { state } = useLocation()
    const [screenData, setScreenData] = useState<ScreenData | null>(state ? state as ScreenData : null)
    const [sending, setSending] = useState(false)
    const messageList = useRef(null)
    useEffect(() => {
        const unsubscribe = onMessage(messaging, fetchData)
        fetchData()
        return unsubscribe
    }, [id])
    function checkForUnseenCount() {
        unseenCount().then((res) => {
            appData.unseenCount = res.data
            setAppData({ ...appData })
        })
    }
    function fetchData() {
        checkForUnseenCount()

        if (parseInt(id) > 0) {
            fetchConversation(parseInt(id)).then((res) => {
                // console.log(res.data)
                setScreenData(res.data)
                setTimeout(scrollToBottom, 300)

            }).catch((err) => {
                if (err.response.status === 406) {
                    history.goBack()
                }
            })
        }
    }
    function scrollToBottom() {
        const container = document.querySelector('.messages .rce-mlist')
        if (container) {
            container.scrollTo(0, container.scrollHeight)
        }
    }
    function goBack() {
        history.goBack()
    }
    const onSendImage = (e: any) => {
        // get a reference to the file
        const file = e.target.files[0];
        if (!screenData || !file || !['image/png', 'image/jpeg'].includes(file.type)) {
            return
        }
        // encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
            // log to console
            // logs data:<type>;base64,wL2dvYWwgbW9yZ...
            // console.log(reader.result.slice(reader.result.search('base64,')+7))
            if (!reader || !reader.result || typeof (reader.result) !== 'string') {
                return
            }
            const imageString = reader.result.slice(reader.result.search('base64,') + 7)
            setSending(true)
            sendImage(screenData.conversation.id, imageString).then((res) => {
                const newScreenData = res.data as ScreenData
                setScreenData(newScreenData)
                setTimeout(scrollToBottom, 300)
            }).finally(() => {
                setSending(false)
            })
        };
        reader.readAsDataURL(file);
    }
    function onSend() {
        const messageField = document.querySelector('.conversation-message .rce-input') as any
        if (!messageField || !messageField.value || !screenData || screenData.conversation.id === 17) {
            return
        }
        setSending(true)
        sendMessage(screenData.conversation.id, messageField.value).then((res) => {
            const newScreenData = res.data as ScreenData
            setScreenData(newScreenData)
            setTimeout(scrollToBottom, 300)
        }).finally(() => {
            setSending(false)
        })
    }
    const formateMessagenData = (message: ChatMessage) => {
        return {
            avatar: message.avatar ? (message.avatar.search("https") > -1 ? message.avatar : baseUrl + message.avatar) : "",
            title: message.title,
            position: message.position,
            type: message.image_name ? 'photo' : message.type,
            text: message.image_name ? "" : message.text,
            data: message.image_name ? {
                uri: baseUrl + 'images/messenger/' + message.image_name,
                status: {
                    click: true,
                    loading: 0,
                }
            } : {},
            date: new Date(message.date),
        } as MessageType
    }
    return (
        <IonRow>
            <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                <div className='page-container'>
                    {screenData ? <div id="conversation">
                        <div className="page-toolbar">
                            <IonButton onClick={goBack} color='secondary'>
                                <IonIcon icon={arrowBack} />
                            </IonButton>
                            <span className="title">{screenData.conversation.name}</span>
                            {screenData.conversation.id !== 17 && <>
                                <UpdateTitleModal data={screenData} setData={setScreenData} />
                                <DeleteConfirmationModal data={screenData} />
                            </>}
                        </div>
                        <MessageList
                            referance={messageList}
                            className='messages'
                            lockable={true}
                            dataSource={screenData.messages.map(formateMessagenData)}
                            onClick={(message: any) => {
                                if (message && message.data && message.data.uri) {
                                    window.open(message.data.uri, '_blank')?.focus();
                                }
                            }}
                        />
                        {screenData.conversation.id !== 17 && !sending && <Input
                            maxHeight={100}
                            placeholder="Type your message..."
                            multiline={true}
                            className="conversation-message"
                            rightButtons={
                                (<>
                                    <IonButton onClick={() => {
                                        const myLink = document.getElementById('fileField');
                                        myLink?.click();
                                    }}><IonIcon icon={image} /></IonButton>
                                    <input type="file" accept=".jpg,.jpeg,.png" id="fileField" style={{ display: 'none' }}
                                        onChange={onSendImage}
                                    />
                                    <IonButton
                                        onClick={onSend}
                                    ><IonIcon icon={paperPlane} /></IonButton>
                                </>)
                            } />}
                    </div> : null}
                </div>
            </IonCol>
        </IonRow>
    );
};
const ConversationPage: React.FC = () => {
    return (
        <IonContent fullscreen>
            <Header />
            <Body />
            <Footer />
        </IonContent>
    );
};

export default ConversationPage;
