import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { baseUrl } from '../../configs/app';
import {SimpleProduct} from '../../types/product.type';
import './ProductListItem.scss'
import { eye } from 'ionicons/icons';
import { useRef } from 'react';
import QuickView from './QuickView';
type Props = {
    product: SimpleProduct
}
const ProductListItem: React.FC<Props> = ({ product }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    return <QuickView productId={product.id} title={product.name} >
        <IonItem className='product' onClick={() => modal.current?.present()}>
            <img src={baseUrl + product.image} slot='start' alt="" />
            <div className='name'>
                <IonText>{product.name}</IonText>
            </div>
            <div className='pricing'>
                <IonText className='regular'>{product.regular_price} </IonText>
                <IonText className='sale' color='primary'>{product.sale_price}/=</IonText>
            </div>
        </IonItem>
    </QuickView>
};

export default ProductListItem;
