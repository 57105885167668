import {
    IonButton, IonCol, IonContent, IonFooter, IonHeader, IonInput, IonItem,
    IonLabel,
    IonNote, IonRow, IonSpinner, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import loginService from '../../../services/loginService';
import { useAuthContext, useAuthContextUpdater } from '../../../services/auth-context.service';
import { authModalRef } from '../AuthModal';
import { resetPassword } from '../../../functions/forgor-pass.service';
type FormFields = {
    otp: string
    password: string
    confirmPassword: string
}
const ForgotPassOtp: React.FC = () => {
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    const submitBtnRef = useRef<HTMLInputElement>(null)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [presentToast] = useIonToast();
    function validator(values: FormFields) {
        const errors = {} as FormFields;

        if (!values.otp) {
            errors.otp = 'Required';
        } else if (
            values.otp.length != 6
        ) {
            errors.otp = '6 Character Required';
        } else if (
            !values.otp.match(/([a-z0-9]){6}/g)
        ) {
            errors.otp = 'Invalid OTP';
        }
        if (!values.password) {
            errors.password = 'Required';
        }

        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'Required';
        } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Password Don't Match";
        }

        return errors;
    }
    function onSubmit(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        const { setSubmitting } = formikHelpers
        // console.log(values.otp);
        if (!authData.forgotPassPayload) {
            return
        }
        setSubmitting(true);
        setIsFormSubmitting(true)
        resetPassword(authData.forgotPassPayload, values.otp, values.password, values.confirmPassword).then((response) => {
            presentToast({
                message: "পাসওয়ার্ড রিসেট সুসম্পন্ন হয়েছে",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            setAuthData({ ...authData, screen: "login" })
        }).catch(err => {
            presentToast({
                message: err.response.data.message ? err.response.data.message : "Something Went Wrong",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
            setIsFormSubmitting(false)
        })

    }
    if (authData.forgotPassPayload && authData.forgotPassPhone) {
        return (
            <>
                <IonContent color="light">
                    <Formik
                        initialValues={{} as FormFields}
                        validate={validator}
                        onSubmit={onSubmit}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit} style={{ padding: 20, maxWidth: 400, textAlign: 'center' }}>
                                <img src="/assets/icon/otp-Icon.webp" alt="" height={130} />
                                <h2>An OTP was sent to {authData.forgotPassPhone}</h2>
                                <IonItem className={errors.otp && touched.otp ? 'ion-invalid' : undefined}>
                                    <IonInput name='otp' placeholder='Enter 6 Digit Otp'
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.otp} />
                                    {errors.otp && touched.otp && <IonNote slot='error'>{errors.otp}</IonNote>}
                                </IonItem>
                                <IonItem className={errors.password && touched.password ? 'ion-invalid' : undefined}>
                                    <IonLabel position="floating">Password</IonLabel>
                                    <IonInput name='password' type='password' min={1000000000} max={1999999999}
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password} />
                                    {errors.password && touched.password && <IonNote slot='error'>{errors.password}</IonNote>}
                                </IonItem>
                                <IonItem className={errors.confirmPassword && touched.confirmPassword ? 'ion-invalid' : undefined}>
                                    <IonLabel position="floating">Confirm Password</IonLabel>
                                    <IonInput name='confirmPassword' type='password' min={1000000000} max={1999999999}
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword} />
                                    {errors.confirmPassword && touched.confirmPassword && <IonNote slot='error'>{errors.confirmPassword}</IonNote>}
                                </IonItem>
                                <input style={{ display: 'none' }} disabled={isSubmitting} type='submit' ref={submitBtnRef} />
                            </form>
                        )}
                    </Formik>
                </IonContent>
                <IonFooter>
                    {isFormSubmitting ? <div style={{ textAlign: 'center' }}>
                        <IonSpinner color='primary' />
                    </div> : <IonRow>
                        <IonCol>
                            <IonButton onClick={() => setAuthData({ ...authData, screen: "forgot-pass-phone" })}
                                color="primary" fill="outline" expand="block">
                                পিছনে যান
                            </IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => {
                                submitBtnRef.current?.click()
                            }} color="primary" expand="block">{
                                    isFormSubmitting ?
                                        "রিসেট পাসওয়ার্ড করা হচ্ছে...." :
                                        "রিসেট পাসওয়ার্ড"
                                }</IonButton>
                        </IonCol>
                    </IonRow>}
                </IonFooter>

            </>
        );
    } else {
        return <>

            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>OTP Verification</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" id="checkout-otp">
                <h1 style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}>Something Went Wrong</h1>
            </IonContent>
        </>

    }
};

export default ForgotPassOtp;
