import { apiBaseUrl, apiConfig } from "../../configs/app";
import { SimpleProduct } from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    current_page: number
    data: SimpleProduct[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
function getKey(subCatId: number, pageNo: number = 1){
    return CryptoService.hash('sub-category-' + subCatId + "-products-page-" + pageNo)
}
export function getSubCategoryProducts(subCatId: number, pageNo: number = 1) {
    const localStorageKey = getKey(subCatId, pageNo)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchSubCategoryProducts(subCatId: number, pageNo: number = 1) {
    const localStorageKey = getKey(subCatId, pageNo)
    const urlPart = `?items_per_page=12&subcategory_id=${subCatId}&img=web&page=${pageNo}`
    let promise:Promise<Response>
    if(loginService.currentUser.token){
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        } as RequestInit;
        promise = fetch(apiConfig.privateProducts + urlPart,requestOptions)
    }else{
        promise = fetch(apiConfig.products + urlPart)
    }
    return promise.then((res) => res.json())
        .then((res: any) => {
            if (res.products && res.products.total > 0) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res.products)))
                return res.products as ApiResponse
            } else {
                return null
            }
        })
}
