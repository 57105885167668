// frontend\src\pages\kegal\PrivacyPolicy.tsx

import React from 'react';
import {
    IonContent,
    IonText,
    IonList,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
} from '@ionic/react';
import './PrivacyPolicy.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../configs/app';

const PrivacyPolicy: React.FC = () => {

    return (
        <IonContent fullscreen>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content' id="privacy-policy-content">
                    <IonGrid>
                        <IonRow>
                            <IonCol size="12" className="privacy-policy-container">
                                <IonText color="medium">
                                    <h1>Privacy Policy</h1>
                                </IonText>
                                <IonText color="medium">
                                    <p><em><b>Last Updated: </b>23rd Oct 2023</em></p>
                                </IonText>

                                <IonText>
                                    <p>
                                        Welcome to <strong>Bbazar</strong> (“we,” “our,” “us”). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website, use our Reseller App, or User App (collectively, the “Services”). Please read this policy carefully to understand our views and practices regarding your personal data.
                                    </p>
                                </IonText>

                                <hr />

                                <IonText color="dark">
                                    <h2>1. Introduction</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        Bbazar is one of the largest reselling platforms in Bangladesh, offering over 10,000 products listed on our platform. We value your trust and are dedicated to safeguarding your personal information.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>2. Information We Collect</h2>
                                </IonText>

                                <IonText color="dark">
                                    <h3>a. Personal Information</h3>
                                </IonText>
                                <IonText>
                                    <p>We may collect the following personal information when you use our Services:</p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Contact Information:</strong> Name, email address, phone number, and mailing address.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Account Information:</strong> Username, password, and profile details.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Payment Information:</strong> Credit/debit card details, billing address, and transaction history.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Usage Data:</strong> Information about how you use our Services, such as browsing history, search queries, and purchase history.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonText>

                                <IonText color="dark">
                                    <h3>b. Non-Personal Information</h3>
                                </IonText>
                                <IonText>
                                    <p>We may collect non-personal information that does not directly identify you, such as:</p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Technical Data:</strong> IP address, browser type, device information, and operating system.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Analytics Data:</strong> Aggregated data on user behavior and trends.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonText>

                                <IonText color="dark">
                                    <h2>3. How We Use Your Information</h2>
                                </IonText>
                                <IonText>
                                    <p>We use the collected information for various purposes, including:</p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Providing and Improving Services:</strong> To operate, maintain, and enhance our Services.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Personalization:</strong> To tailor content and recommendations to your preferences.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Communication:</strong> To send you updates, promotional materials, and respond to your inquiries.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Transaction Processing:</strong> To process your orders, payments, and manage your account.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Security:</strong> To protect against fraud, unauthorized access, and other security threats.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Compliance:</strong> To comply with legal obligations and enforce our Terms of Service.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                </IonText>

                                <IonText color="dark">
                                    <h2>4. How We Share Your Information</h2>
                                </IonText>
                                <IonText>
                                    <p>We may share your information in the following circumstances:</p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Service Providers:</strong> With third-party vendors who assist us in operating our Services, such as payment processors, shipping companies, and marketing agencies.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Legal Requirements:</strong> When required by law, regulation, or legal process, or to protect our rights and safety.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>With Your Consent:</strong> We may share your information with third parties if you have given us explicit consent to do so.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                    <p><strong>Note:</strong> We do not sell, rent, or lease your personal information to third parties for their marketing purposes without your explicit consent.</p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>5. Data Security</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure servers, and regular security assessments. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>6. Your Rights</h2>
                                </IonText>
                                <IonText>
                                    <p>Depending on your jurisdiction, you may have the following rights regarding your personal information:</p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Access:</strong> The right to request access to your personal data.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Correction:</strong> The right to request correction of inaccurate or incomplete data.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Deletion:</strong> The right to request deletion of your personal data under certain conditions.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Restriction:</strong> The right to restrict processing of your personal data.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Portability:</strong> The right to receive your data in a structured, commonly used format.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Objection:</strong> The right to object to certain types of data processing.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                    <p>
                                        To exercise these rights, please contact us using the contact information provided below.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>7. Cookies and Tracking Technologies</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        We use cookies and similar tracking technologies to enhance your experience on our Services. Cookies help us:
                                    </p>
                                    <IonList>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Remember Your Preferences:</strong> Such as language settings and login details.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Analyze Usage:</strong> To understand how users interact with our Services.</IonLabel>
                                        </IonItem>
                                        <IonItem lines="none">
                                            <IonLabel><strong>Personalize Content:</strong> To provide relevant advertisements and product recommendations.</IonLabel>
                                        </IonItem>
                                    </IonList>
                                    <p>
                                        You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect the functionality of our Services.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>8. Third-Party Links</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        Our Services may contain links to third-party websites, applications, or services that are not operated by us. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party sites you visit.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>9. Children’s Privacy</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected such information, we will take steps to delete it promptly.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>10. Changes to This Privacy Policy</h2>
                                </IonText>
                                <IonText>
                                    <p>
                                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the “Last Updated” date. Your continued use of the Services after such changes constitutes your acceptance of the updated policy.
                                    </p>
                                </IonText>

                                <IonText color="dark">
                                    <h2>11. Contact Us</h2>
                                </IonText>
                                <IonText>

                                    <p>
                                        To see the details <Link to={appSubDir + "contact"}>CLick Here</Link>.
                                    </p>
                                </IonText>

                                <hr />

                                <IonText>
                                    <p><strong>Effective Date:</strong> 23rd Oct 2023</p>
                                </IonText>

                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default PrivacyPolicy;
