import { IonButton, IonCol, IonContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import { appSubDir } from '../configs/app';

const NotFound: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ textAlign: 'center', minHeight: 600 }}>
                        <h1 style={{ marginTop:100, marginBottom:100 }}>Sorry ! Nothing Found</h1>
                        <IonButton routerLink={appSubDir} fill='outline'>
                            Go To Home
                        </IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default NotFound;
