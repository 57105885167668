import axios from "axios";
import { apiBaseUrl } from "../configs/app";

export function requestOTP(phone: string, reCaptchToken: string) {

    return axios.post(apiBaseUrl + "forget-pass/otp", { phone, reCaptchToken }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
}
export function resetPassword(payload: string, otp: string, password: string, confirmPassword: string) {

    return axios.post(apiBaseUrl + "forget-pass/reset", { payload, otp, password, confirmPassword }, {
        headers: {
            'Content-Type': "application/json",
            'Accept': "application/json"
        }
    })
}
