import { IonButton, IonContent, IonSpinner} from '@ionic/react';
import { onScroll } from '../../services/sticky-menu.service';
import { appSubDir } from '../../configs/app';
import { useEffect } from 'react';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import shoppingCartService from '../../services/shop/cart.service';
import { useHistory, useParams } from 'react-router';

const PaymentVerify: React.FC = () => {
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const { cart } = appData
    const { valID } = useParams() as any
    const history = useHistory()
    useEffect(() => {
        if (cart.itemCount < 1) {
            return
        }
        shoppingCartService.placeSslOrder(valID).then((response) => {
            if (response.data.invoice_id) {
                shoppingCartService.clearCart()
                setAppData({ ...appData, cart: shoppingCartService.shoppingCart })
                history.push(appSubDir+"payment/success",response.data)
            }
        }).catch(errPromise => {
            errPromise.then((errResponse: any) => {
                console.error(errResponse)
            })
            history.push(appSubDir+"payment/cancelled")
        })
    }, [valID])
    return (
        <IonContent id='standalone-page' fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <div id='standalone-page'>
                <div className='box'>
                    {cart.itemCount < 1 ? <>
                        <h1 style={{ marginTop: 100, marginBottom: 50 }}>Cart Empty</h1>
                        <div>
                            <img src="/assets/img/cart.webp" alt="no cart" style={{ width: 300, marginBottom: 50 }} />
                        </div>
                        <IonButton routerLink={appSubDir} fill='outline'>
                            Go To Shop
                        </IonButton>
                    </> : <>
                        <IonSpinner color='primary' style={{ marginTop: 50, marginBottom: 50 }} />
                        <h1 style={{ marginBottom: 50 }}>Verifying Payment...</h1>
                        <p>Don't Close The Window</p>
                    </>}
                </div>
            </div>
        </IonContent>
    );
};

export default PaymentVerify;
