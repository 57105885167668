import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { useRef, useState } from "react";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { book, closeCircle } from "ionicons/icons";
import { authModalRef } from "../Auth/AuthModal";
import loginService from "../../services/loginService";

export let withdrawDetailsModalRef: React.RefObject<HTMLIonModalElement>
export const WithdrawDetailsButton: React.FC = () => {
    return <>
        <IonItem button={true} onClick={() => {
            authModalRef.current?.dismiss()
            withdrawDetailsModalRef.current?.present()
        }}>
            <IonIcon slot="start" icon={book} />
            <IonText>Withdraw Details</IonText>
        </IonItem>
    </>
};
export const WithdrawDetailsModal: React.FC = () => {
    withdrawDetailsModalRef = useRef<HTMLIonModalElement>(null)
    const textareaRef = useRef<HTMLIonTextareaElement>(null)
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const [textareaDisabled, setTextareaDisabled] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const dumy = {
        bank: "Bank Name\t: Dutch Bangla Bangla Bank LTD\r\nBranch Name\t: Dhanmondi Branch\r\nA/C Name\t: " + appData.user.name + "\r\nA/C No\t\t: XXX-XXXX-XXXXX-XXXX-XXXX",
        rocket: "Rocket Personal Account : 018XXXXXXXX",
        bkash: "Bkash Personal Account : 017XXXXXXXX"
    }
    function onPresent() {
        if (textareaRef.current) {
            textareaRef.current.value = loginService.currentUser.bkash_rocket
        }
        setTextareaDisabled(true)
        setBtnDisabled(true)
        loginService.load().then((res) => {
            appData.user = loginService.currentUser
            setAppData({ ...appData })
        }).finally(() => {
            setTextareaDisabled(false)
            if (textareaRef.current) {
                textareaRef.current.value = loginService.currentUser.bkash_rocket
            }
        })
    }
    function onUpdate() {
        if (textareaRef.current && textareaRef.current.value) {
            setTextareaDisabled(true)
            setBtnDisabled(true)
            setBtnLoading(true)
            loginService.editDetails({ bkash_rocket: textareaRef.current.value }).then((res) => {
                appData.user = loginService.currentUser
                setAppData({ ...appData })
            }).finally(() => {
                setTextareaDisabled(false)
                setBtnLoading(false)
                if (textareaRef.current) {
                    textareaRef.current.value = loginService.currentUser.bkash_rocket
                }
            })
        }
    }
    if (appData.user.role_id != 2) {
        return null
    }
    return <IonModal ref={withdrawDetailsModalRef} onDidPresent={onPresent}>
        <IonHeader>
            <IonToolbar color="light">
                <IonTitle>Payment Withdrawal Method</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
            <IonItem>
                <IonTextarea onKeyUp={() => setBtnDisabled(false)} disabled={textareaDisabled} ref={textareaRef} rows={6} placeholder="Enter Your Withdraw Details" />
            </IonItem>
            <h4 className="text-center">Samples</h4>
            <IonItem>
                <IonInput readonly={true} value={dumy.bkash} />
            </IonItem>
            <IonItem>
                <IonInput readonly={true} value={dumy.rocket} />
            </IonItem>
            <IonItem>
                <IonTextarea readonly={true} rows={4} value={dumy.bank} />
            </IonItem>
        </IonContent>
        <IonFooter>
            <IonButton disabled={btnDisabled} onClick={onUpdate} color="primary" expand="block">
                {btnLoading ? <IonSpinner /> : "Update"}
            </IonButton>
            {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => withdrawDetailsModalRef.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton>}
        </IonFooter>
    </IonModal>
};
