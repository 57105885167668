// firebase-config.ts
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyA6S0cDlr-Xv4io754kW62jggw883RBbtA",
    authDomain: "bbazar-com-bd.firebaseapp.com",
    databaseURL: "https://bbazar-com-bd.firebaseio.com",
    projectId: "bbazar-com-bd",
    storageBucket: "bbazar-com-bd.appspot.com",
    messagingSenderId: "587693243202",
    appId: "1:587693243202:web:ca9a876dae98a8b2aea6db",
    measurementId: "G-EFGRSPX5CS"
};
export const vapidKey = 'BFXQUx-6H_r-2DA8X65gmyhn88KHTvhsvjV8uV-7PWlNBPjrdm8oWR8bhm-R16zeFWk6z0W3SruLTyl3T9h8xh0'

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
