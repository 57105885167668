import { Link } from 'react-router-dom';
import { appSubDir } from '../../configs/app';
import { IonCol, IonRow } from '@ionic/react';
import CartModal from '../Shop/CartModal';
import AuthModal from '../Auth/AuthModal';
import WishlistButton from '../Shop/WishlistButton';
import './Header.scss'
import BottomMenu from './MobileMenu';
import SearchBar from './SearchBar';
import PrimaryMenu from './PrimaryMenu';
import QuickLinksModal from './QuickLinksModal';
import InboxBtn from './InboxBtn';
import { useAppContext } from '../../services/app-context.service';

const Header: React.FC = () => {
    const { innerWidth } = useAppContext()

    return <>
        {/* <TopBar /> */}
        <header>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <IonRow>
                        <IonCol size='12' sizeMd='6' sizeLg='6' sizeXl={innerWidth>1440 ? "3":"4"}>
                            <Link to={appSubDir} className='brand'>
                                <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                                <h1>Bbazar</h1>
                            </Link>
                        </IonCol>
                        {innerWidth > 1024 && <IonCol sizeXl={innerWidth>1440 ? "6":"4"}>
                            <SearchBar />
                        </IonCol>}
                        <IonCol size='12' sizeMd='6' sizeLg='6' sizeXl={innerWidth>1440 ? "3":"4"}>
                            <div className='buttons'>
                                <InboxBtn />
                                <WishlistButton />
                                <CartModal />
                                <AuthModal />
                                <QuickLinksModal />
                            </div>
                        </IonCol>
                    </IonRow>

                    {innerWidth <= 1024 && <SearchBar />}
                </IonCol>
            </IonRow>
        </header>
        {innerWidth > 600 && <PrimaryMenu />}
    </>
};

export default Header;
