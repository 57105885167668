import { InputChangeEventDetail, IonBadge, IonButton, IonCol, IonContent, IonIcon, IonInput, IonItem, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { fetchAllInvoices, getAllInvoices, getBadgeColor } from '../../services/account/invoices.service';
import { useHistory } from 'react-router';
import { search } from 'ionicons/icons';

const BDT = new Intl.NumberFormat('en-IN');
const Invoices: React.FC = () => {
    const history = useHistory()
    const [loading, setLoadling] = useState(false)
    const response = getAllInvoices("")
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [items, setItems] = useState(response ? response.data : null)
    const [searchKey, setSearchKey] = useState("")
    const input = useRef<HTMLIonInputElement>(null)
    useEffect(() => {
        const local = getAllInvoices(searchKey, page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchAllInvoices(searchKey, page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }, [page, searchKey])
    function showDetails(id: string) {
        history.push(appSubDir + "invoices/" + id)
    }
    function loadInvoices(pageNo:number){
        setPage(pageNo)
    }
    function onChange(e:any){
        setSearchKey(e.target.value)
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>No Order Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {items && <div className='page-container'>
                            <IonRow style={{ marginTop: 60, marginBottom: 50 }}>
                                <IonCol sizeMd='4' offsetMd='4'>
                                    <div className='title-bar'>
                                        <h1>My Invoices</h1>
                                    </div>
                                </IonCol>
                                <IonCol sizeMd='4'>
                                    <IonItem style={{ marginTop: 7 }}>
                                        <IonIcon icon={search} slot='start' />
                                        <IonInput type='number' ref={input} clearInput={true} debounce={500} onIonChange={onChange} placeholder='Search By Order ID' />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <div style={{ marginTop: 30 }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th className='mobile-invisible'>Date</th>
                                            <th>Sub Total</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                            if (item.status)
                                                return <tr style={{ cursor: "pointer" }} key={index} onClick={() => showDetails(item.id)}>
                                                    <td className='text-center'>#{item.id.toString().padStart(6, '0')}</td>
                                                    <td className='text-center mobile-invisible'>{new Date(Date.parse(item.created_at)).toDateString()}</td>
                                                    <td className='text-end'>{BDT.format(parseFloat(item.total))}/=</td>
                                                    <td className='text-center'><IonBadge color={getBadgeColor(item.status)}>{item.status}</IonBadge></td>
                                                </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadInvoices} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Invoices;
