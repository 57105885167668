import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { IonBadge, IonButton, IonIcon, IonText } from '@ionic/react';
import { mailOpenOutline, mailOutline } from 'ionicons/icons';
import { appSubDir } from '../../configs/app';
import { useEffect } from 'react';

const InboxBtn: React.FC = () => {
    const { user, unseenCount } = useAppContext()
    if (!user.token) {
        return null
    }
    return (
        <IonButton fill='clear' routerLink={appSubDir + "conversations"}>
            <IonIcon size='large' icon={unseenCount > 0 ? mailOutline : mailOpenOutline} />
            {unseenCount > 0 && <IonBadge color='danger'><IonText color='light'>{unseenCount}</IonText></IonBadge>}
        </IonButton>
    );
};

export default InboxBtn;
