import { IonAvatar, IonBadge, IonCol, IonContent, IonItem, IonLabel, IonList, IonRow, useIonRouter } from '@ionic/react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import './Conversations.scss'
import { useEffect, useState } from 'react';
import { fetchConversations } from '../../services/account/message.service';
import { appSubDir, baseUrl } from '../../configs/app';
import Conversation, { ChatUser, FCMPayload } from '../../types/conversation.type';
import { getToken, onMessage } from 'firebase/messaging';
import { messaging, vapidKey } from '../../configs/firebase.config';
import { useHistory } from 'react-router';


type InboxData = {
    allUsers: ChatUser[]
    conversations: Conversation[]
};
const Conversations: React.FC = () => {
    const [inboxData, setInboxData] = useState<InboxData | null>(null)
    const history = useHistory();

    const goToCreateConversationPage = (user: ChatUser) => {
        history.push({
            pathname: appSubDir + "create-conversation",
            state: user
        });
    };
    function payloadProcessor(payload:any) {
        setInboxData((currnt) => {
            if (!!payload.data && !!currnt) {
                const fcmPayload = payload.data as FCMPayload
                const convIndex = currnt.conversations.findIndex((item) => (item.id === parseInt(fcmPayload.conversation_id)))
                if (convIndex >= 0) {
                    const conv = currnt.conversations[convIndex]
                    if (conv) {
                        conv.lms_name = fcmPayload.title
                        if (fcmPayload.avatar) conv.lms_avatar = fcmPayload.avatar
                        if (fcmPayload.text) conv.last_message = fcmPayload.text
                        conv.unseen_count++
                    }
                }
            }
            return { ...currnt } as InboxData
        })
        // Customize your notification here
        const notificationTitle = payload.notification?.title ?? '';
        const notificationOptions = {
            body: payload.notification?.body,
            icon: payload.notification?.icon,
        };

        new Notification(notificationTitle, notificationOptions);
    }
    useEffect(() => {
        let unsubscribe = ()=>{}
        fetchConversations().then((res) => {
            // console.log(res.data)
            setInboxData(res.data)
            unsubscribe = onMessage(messaging,payloadProcessor);
        })
        return unsubscribe
    }, [])
    return (
        <IonContent fullscreen>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        <div id="conversations">
                            <div className="page-toolbar">
                                <span className="title">My Inbox</span>
                            </div>
                            <IonList>
                                {inboxData && inboxData.conversations.map((item, index) => (
                                    <IonItem key={item.id + "-" + index} routerLink={appSubDir + "conversations/" + item.id}>
                                        <IonAvatar slot="start">
                                            <img src={baseUrl + item.lms_avatar} alt={`${item.lms_name}'s avatar`} />
                                        </IonAvatar>
                                        <IonLabel>
                                            <h2>{item.name}</h2>
                                            <p>{item.last_message} - {item.lms_name}</p>
                                        </IonLabel>
                                        <div className="item-end">
                                            {item.unseen_count > 0 && (
                                                <IonBadge color="primary">{item.unseen_count}</IonBadge>
                                            )}
                                            <IonLabel className="chat-time">{item.lms_time}</IonLabel>
                                        </div>
                                    </IonItem>
                                ))}
                                {inboxData && inboxData.allUsers.map((chat, index) => (
                                    <IonItem key={chat.id + "-" + index} onClick={() => {
                                        goToCreateConversationPage(chat)
                                    }} button>
                                        <IonAvatar slot="start">
                                            <img src={baseUrl + chat.photo} alt={`${chat.name}'s avatar`} />
                                        </IonAvatar>
                                        <IonLabel>
                                            <h2>{chat.name}</h2>
                                            <p>Click to send a new support message</p>
                                        </IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Conversations;
