import { apiBaseUrl } from "../../configs/app";
import { Brand } from "../../types/product.type";
import CryptoService from "../crypto.services";
const localStorageKey = CryptoService.hash("all-brands")
export function getAllBrands() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Brand[] : null)
}
export function getBrand(id:number) {
    const localData = getAllBrands()
    const result =  (localData ? localData.find((item)=>item.id===id) : null)
    return result ? result : null
}
export function fetchAllBrands() {
    const url = apiBaseUrl + 'brands'
    return fetch(url).then((res) => res.json())
        .then((items: Brand[]) => {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(items)))
            return items
        })
}
