import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonNote,
    IonText,
    useIonToast
} from '@ionic/react';


import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { useEffect, useRef, useState } from 'react';
import { isCouponEnabled } from '../../services/shop/shipping-costs.service';
import shoppingCartService from '../../services/shop/cart.service';
const PromoCodeField: React.FC = () => {
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const { user, cart } = appData
    const promoCodeApplied = !!cart.promoCode
    const isUserLoggedIn = !!user.token
    const inputRef = useRef<HTMLIonInputElement>(null)
    const [couponEnabled, setCouponEnabled] = useState(false)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [presentToast] = useIonToast();
    useEffect(() => {
        if (isUserLoggedIn) {
            isCouponEnabled().then((res) => setCouponEnabled(res.data))
        }
    }, [])
    if (!couponEnabled) {
        return null
    }
    function handleApplyPromoCode() {
        const prmoCode = inputRef.current?.value
        if (!prmoCode) {
            presentToast({
                message: "No Promo Code Given",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            return
        }
        setLoading(true)
        shoppingCartService.applyPromoCode(prmoCode.toString()).then((cart) => {
            if (cart) {
                setAppData({ ...appData, cart })
            }
        }).catch(() => {
            setError("Promo Code Was Not Valid")
            setTimeout(() => {
                setError("")
                // inputRef.current && inputRef.current.value = ""
            }, 3000)
        }).finally(() => {
            setLoading(false)
        })
    }
    function handleRemovePromoCode() {
        setAppData({ ...appData, cart: shoppingCartService.removePromoCode() })
    }
    if (promoCodeApplied) {

        return (<IonItem>
            <IonLabel>Promo Code</IonLabel>
            <IonText slot='end'>{cart.promoCode}</IonText>
            <IonButton slot='end' disabled={loading} onClick={promoCodeApplied ? handleRemovePromoCode : handleApplyPromoCode}>Remove</IonButton>
        </IonItem>);
    }
    return (<IonItem className={error ? 'ion-invalid' : undefined}>
        <IonLabel>Promo Code</IonLabel>
        <IonInput ref={inputRef} slot='end' name='promoCode' placeholder='Enter Promo Code' />
        <IonButton slot='end' disabled={loading} onClick={promoCodeApplied ? handleRemovePromoCode : handleApplyPromoCode}>{promoCodeApplied ? "Remove" : "Apply"}</IonButton>
        {error && <IonNote slot='error'>{error}</IonNote>}
    </IonItem>);
};

export default PromoCodeField;
