
import AES from 'crypto-js/aes';
import MD5 from 'crypto-js/md5';
import enc from 'crypto-js/enc-utf8';
import { lockerHandler } from '../configs/crypto.config';

const CryptoService = {
    hash(message: string) {
        return MD5(message).toString()
    },
    encrypt(message: string) {
        return AES.encrypt(message, lockerHandler).toString()
    },
    decrypt(encrypted: string) {
        return AES.decrypt(encrypted,lockerHandler).toString(enc)
    }
}
export default CryptoService
