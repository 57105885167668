
export function onScroll(e: any) {
    // document.querySelector('.home-slider').scrollHeight
    // console.log(e.target)
    const stickyBar = document.querySelector('header')
    if (!stickyBar || e.detail.velocityY === 0 || window.screen.width <= 575) {
        return null
    }
    if (e.detail.scrollTop > stickyBar.scrollHeight) {
        stickyBar.classList.add('sticky')
        e.target.style.setProperty('--padding-top',stickyBar.scrollHeight+"px")
    } else {
        stickyBar.classList.remove('sticky')
        e.target.style.removeProperty('--padding-top')
    }

    if (e.detail.velocityY < 0) {
        stickyBar.classList.add('show')
    } else {
        stickyBar.classList.remove('show')
    }

    // if(e.detail.velocityY < 0){
    //     console.log('Sticky')
    // console.log('Scroll Height', e.detail.startY, e.detail.currentY)
    // console.log('Scroll Height',e.detail)
}
