import { IonButton, IonButtons, IonCol, IonRow, IonText } from '@ionic/react';
import './PrimaryMenu.scss'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { appSubDir } from '../../configs/app';
import { useEffect, useState } from 'react';
import { fetchCategories, localCategories } from '../../services/products/all-category.service';
import { getMultipleRandom } from '../../functions/array.functions';
import Category from '../../types/category.type';
import { preventOverflow } from '../../functions/string.functions';
import { defaultAuthData } from '../../services/auth-context.service';
import { authModalRef } from '../Auth/AuthModal';
import { useAppContext } from '../../services/app-context.service';

const PrimaryMenu: React.FC = () => {
    const appData = useAppContext()
    const [items, setItems] = useState(localCategories())
    useEffect(() => {
        if (items.length < 1) {
            fetchCategories().then((categories) => {
                setItems(categories)
            })
        }
    }, [])
    return (
        <nav id="primary-menu">
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding'>
                    <div className='body'>
                        <ul className='menu'>
                            <li>
                                <Link to={appSubDir + "about"}>About</Link>
                            </li>
                            <li>
                                <Link to={appSubDir + "shop"}>Shop</Link>
                            </li>
                            {getMultipleRandom(
                                items.filter((item) => (item.subCategory.length > 5)),
                                window.screen.width <= 1024 ? 3 : 5
                            ).map((item: Category, index) => <li key={index}>
                                <Link to={appSubDir + "categories/" + item.id}>{preventOverflow(item.title, 15)}</Link>
                                <ul className='level level-1'>
                                    {getMultipleRandom(item.subCategory, 12).map((subCat, subCatIndex) => <li key={subCatIndex}>
                                        <Link to={appSubDir + "categories/" + subCat.category_id + "/sub-categories/" + subCat.id}>{preventOverflow(subCat.sub_title, 15)}</Link>
                                    </li>)}
                                </ul>
                            </li>)}
                            <li>
                                <Link to={appSubDir + "contact"}>Contact</Link>
                            </li>
                        </ul>
                        {!appData.user.token && <div className='text-end'>
                            {/* <IonButtons>
                                <IonButton fill='clear' href='tel:+8801689636569'>
                                    <IonText color="light" slot='start' style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPhone} /></IonText>
                                    <IonText color="light"><b>+8801689636569</b></IonText>
                                </IonButton>
                                <IonButton fill='clear' href='tel:+8801676457427'>
                                    <IonText color="light" slot='start' style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPhone} /></IonText>
                                    <IonText color="light"><b>+8801676457427</b></IonText>
                                </IonButton>
                                <IonButton fill='clear' href='https://www.facebook.com/bbazarbbazarbbazar' target='_blank'>
                                    <IonIcon icon={logoFacebook} color="light" slot='start' />
                                    <IonText color="light"><b>FB Page</b></IonText>
                                </IonButton>
                            </IonButtons> */}
                            <IonButtons>
                                <IonButton fill='clear' onClick={() => {
                                    defaultAuthData.role_id = 2
                                    authModalRef.current?.present()
                                }}>
                                    <IonText color="light" slot='start' style={{ marginRight: 10 }}><FontAwesomeIcon icon={faUserCircle} /></IonText>
                                    <IonText color="light"><b>Reseller Login</b></IonText>
                                </IonButton>
                            </IonButtons>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
        </nav>
    );
};

export default PrimaryMenu;
