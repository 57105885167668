import { apiBaseUrl } from "../../configs/app";
import { SimpleProduct } from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    current_page: number
    data: SimpleProduct[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
function getKey(pageNo: number = 1) {
    return CryptoService.hash("wishlist-products-" + pageNo)
}
export function getWishlistProducts(pageNo: number = 1) {
    const localStorageKey = getKey(pageNo)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchWishlistProducts(pageNo: number = 1) {
    return loginService.privateGetApiCall(apiBaseUrl + "v2/wishlist", { page: pageNo,web_image:'web_image' }).then((res) => {
        const ApiResponse = res.data as ApiResponse
        if (ApiResponse && ApiResponse.total > 0) {
            const localStorageKey = getKey(pageNo)
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(ApiResponse)))
            return ApiResponse
        } else {
            return null
        }
    })
}
type ToggleApiResponse = {
    type?: "success"|"info"
    message?: string
}
export function toggleWishlistProduct(id: number) {
    return loginService.privateGetApiCall(apiBaseUrl + `v2/wishlist/${id}/toggle`,).then((res) => {
        return res.data as ToggleApiResponse
    })
}
type StatusApiResponse = {
    status: boolean
}
export function statusWishlistProduct(id: number) {
    return loginService.privateGetApiCall(apiBaseUrl + `v2/wishlist/${id}`,).then((res) => {
        return res.data as StatusApiResponse
    })
}
