import { apiBaseUrl } from "../../configs/app";
import { SimpleInvoice } from "../../types/shop/invoice.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type Response = {
    current_page: number
    data: SimpleInvoice[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
export function getBadgeColor(status: string) {
    switch (status) {
        case "Complete":
            return 'success'
            break;
        case "Pending":
            return 'primary'
            break;
        case "Processing":
            return 'tertiary'
            break;
        case "Cancelled":
            return 'danger'
            break;
        case "Returned":
            return 'warning'
            break;
        default:
            return 'default'
    }
}
function getKey(searchKey: string, pageNo: number = 1) {
    if (searchKey) {
        return CryptoService.hash(searchKey + "-invoices-" + pageNo)
    }
    return CryptoService.hash("all-invoices-" + pageNo)
}
export function getAllInvoices(searchKey: string, pageNo: number = 1) {
    const localStorageKey = getKey(searchKey, pageNo)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Response : null)
}
export function fetchAllInvoices(searchKey: string, pageNo: number = 1) {
    const localStorageKey = getKey(searchKey, pageNo)
    const url = apiBaseUrl + `invoices?page=${pageNo}` + (searchKey ? `&s=${encodeURIComponent(searchKey)}` : "")
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    } as RequestInit;
    return fetch(url, requestOptions).then((res) => res.json())
        .then((res: any) => {
            if (res && res.total > 0) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res as Response
            } else {
                return null
            }
        })
}
