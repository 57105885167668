import { IonAvatar, IonBadge, IonButton, IonCol, IonContent, IonIcon, IonInput, IonItem, IonItemGroup, IonLabel, IonList, IonRow, IonText, IonTextarea } from '@ionic/react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer';
import './CreateConversation.scss'
import { arrowBack, paperPlane } from 'ionicons/icons';
import { Redirect, useHistory, useLocation } from 'react-router';
import { ChatUser } from '../../types/conversation.type';
import { appSubDir, baseUrl } from '../../configs/app';
import { useState } from 'react';
import { sendNewMessage } from '../../services/account/message.service';


const Body: React.FC = () => {
    const location = useLocation();
    const history = useHistory()
    const [disabled, setDisabled] = useState(false)
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const toUser = location.state ? location.state as ChatUser : false
    function goBack() {
        history.goBack()
    }
    if (!toUser) {
        return null
    }
    function onSend() {
        if (!toUser || !title || !text) {
            console.error("Paremeter Missing")
            return
        }
        setDisabled(true)
        sendNewMessage(toUser.id, title, text).then((res) => {

            if (res.data.conversation.id) {
                history.replace(appSubDir + "conversations/" + res.data.conversation.id, res.data)
            }
        }).finally(() => {
            setDisabled(false)
        })
    }
    return (
        <IonRow>
            <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                <div className='page-container'>
                    <div id="create-conversation">
                        <div className="page-toolbar">
                            <IonButton onClick={goBack} color='secondary'>
                                <IonIcon icon={arrowBack} />
                            </IonButton>
                            <span className="title">New Message</span>
                        </div>
                        <div className="form">
                            <IonItemGroup>
                                <IonItem>
                                    <IonLabel slot="start">
                                        Receiver
                                    </IonLabel>
                                    <IonAvatar slot="start">
                                        <img src={baseUrl + toUser.photo} alt={`${toUser.name}'s avatar`} />
                                    </IonAvatar>
                                    <IonInput readonly={true} value={toUser.name} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel slot="start">
                                        Subject
                                    </IonLabel>
                                    <IonInput value={title} onIonChange={(e) => {
                                        if (e.target.value) {
                                            setTitle(e.target.value.toString())
                                        } else {
                                            setTitle("")
                                        }
                                    }} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel slot="start">
                                        Message
                                    </IonLabel>
                                    <IonTextarea rows={10} value={text} onIonChange={(e) => {
                                        if (e.target.value) {
                                            setText(e.target.value.toString())
                                        } else {
                                            setText("")
                                        }
                                    }} />
                                </IonItem>
                            </IonItemGroup>
                            <IonButton disabled={disabled} onClick={onSend} color='success' expand='full' shape='round'>
                                <IonIcon slot='start' icon={paperPlane} />
                                Start Conversation
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonCol>
        </IonRow>
    );
};
const CreateConversation: React.FC = () => {
    return (
        <IonContent fullscreen>
            <Header />
            <Body />
            <Footer />
        </IonContent>
    );
};

export default CreateConversation;
