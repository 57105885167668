import { IonButton, IonCol, IonContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import './About.scss';
import { appSubDir } from '../configs/app';

const About: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content' id="about-page">
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <img src="assets/img/about.webp" alt="" />
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="about">
                                <h1>About Bbazar</h1>
                                <hr />
                                <p>Bangladesh has become a developing country.  It is considered as the world's fastest economy. But maintaining improved living standard and lifestyle in efficient level is now a great challenge. As a result government along with few entrepreneurs and IT experts have taken a lot of approaches to provide all kinds of facilities for the people of Bangladesh. Among all E-Commerce is an important sector and right now B Bazar is playing an vital role here. B Bazar is going to provide a working facility of 20,000 female persons including students, housewife, unemployed women where they will be able to meet their own necessities and a healthy income staying at home. Even right now B Bazar is successfully running around the whole country along with 700 employees  providing 100% authentic and pure products from door to door every day. In this situation we are dreaming and moving forward accordingly to become one of the most popular and largest online wholesale and retail market place in Bangladesh providing a working facility of 20,000 freelancer in our country.</p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <div className="text-center">
                        <h1>Business Overview</h1>
                        <hr />
                    </div>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <p>B Bazar has started its journey at the end of 2018. Right now B Bazar has already more than 600 registered reseller members working here along with an earning of tk.2000- tk.10,000 where they can work staying at their own house. Besides we have more than 1,000 unregistered reseller members working with our company.</p>

                            <p>Generally, we are most popular wholesale online marketplace in Bangladesh. Also known as Reseller Online platform. There are certain terms and conditions for using and accessing this Online Marketplace B Bazar Site, which are generally applicable to the use of B Bazar domain, site, mobile app, service and site equipment.</p>

                            <p>By using the B Bazar website, you agree to the terms and conditions set forth below and you agree to be bound by all terms and conditions in accordance with the User Agreement.</p>

                            <p>This User Agreement signifies your acceptance of the following terms, which apply only when using the B Bazar site.</p>

                            <p>If you refuse to accept our User Agreement, please refrain from visiting, registering or using the B Bazar website.</p>

                            <p>This website is operated by BBAZAR DOT COM TRADE (Registration Number: 02109137)</p>
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6' className='text-end'>
                            <img src="assets/img/business-overview.webp" alt="" />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <h1>MISSION</h1>
                            <hr />
                            <p>B Bazar is a start-up online market place running successfully for last 2 years. B Bazar is mainly focusing to provide an opportunity of an extra income for 20,000 persons within 2022.</p>

                            <p>We are mainly focusing on the following points:</p>
                            <ol>
                                <li>Creating extra income opportunities for female person specially students and housewives.</li>
                                <li>Providing all kinds of business supports to B2B persons including financial support, products sourcing, and delivery for starting a business.</li>
                                <li>Providing 100% authentic products on a reasonable price to every corner of the country within a very short period of time.</li>
                            </ol>
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <h1>VISION</h1>
                            <hr />
                            <p>E-commerce has become an important issue in this modern age of science and technology. By this time a few entrepreneurs of Bangladesh is taking steps to place an established online market place and B Bazar is one of them.</p>

                            <p>Especially in our country all kinds of job facilities in our country is city centric. But B Bazar is focused on providing an opportunity of an extra income for both rural and urban areas. As a result besides urban female, a female staying at rural area will be able to earn an bright income and an opportunity of establishing online business.</p>
                        </IonCol>
                    </IonRow>
                    <div className="text-center">
                        <IonButton routerLink={appSubDir+'contact'}><IonText>Invest With Us Today</IonText></IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default About;
