import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useRef, useState } from 'react';
import { fetchAllBrands, getBrand, getAllBrands } from '../../services/products/all-brands.service';
import { useParams } from 'react-router';
import { appSubDir } from '../../configs/app';
import { fetchBrandProducts, getBrandProducts } from '../../services/products/brand-products.service';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';
import AllBrands from '../../components/Products/AllBrands';
type CustomParam = {
    brandID: string
}
const Brand: React.FC = () => {
    const { brandID } = useParams() as CustomParam
    const productResponse = getBrandProducts(parseInt(brandID))
    const [item, setItem] = useState(getBrand(parseInt(brandID)))
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    const modal = useRef<HTMLIonModalElement>(null)
    function loadProducts(page: number) {
        const local = getBrandProducts(parseInt(brandID), page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchBrandProducts(parseInt(brandID), page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        setItem(getBrand(parseInt(brandID)))
        loadProducts(1)
    }, [brandID])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        {!item && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {item && <div className='page-container'>
                            <div className='title-bar'>
                                <h6>Brand</h6>
                                <h1>{item?.name}</h1>
                                <AllBrands title='All Other Brands' excludedBrandID={parseInt(brandID)}/>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {items && <ProductsGrid title='Products' products={items} hideHeader={true} />}
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Brand;
