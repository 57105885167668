import { IonButton, IonContent } from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router';
import { useState } from 'react';
import loginService from '../../services/loginService';

const PaymentSuccess: React.FC = () => {
    const location = useLocation() as any
    const history = useHistory()
    useState(()=>{
        setTimeout(()=>{
            if(loginService.currentUser.token){
                history.replace(appSubDir+"invoices/"+location.state.invoice_id)
            }else{
                history.replace(appSubDir+"guest-invoice/"+location.state.invoice_id)
            }
        },2000)
    })
    return (
        <IonContent id='standalone-page' fullscreen>
            <div id='standalone-page'>
                <div className='box'>
                    <FontAwesomeIcon style={{ marginTop: 50, marginBottom: 50 }} color='green' size='10x' icon={faCircleCheck} />
                    <h1 style={{ marginBottom: 50 }}>Your Payment Was Successful.</h1>
                    <p>Redirecting you to invoice page</p>
                </div>
            </div>
        </IonContent>
    );
};

export default PaymentSuccess;
