import { IonButton, IonText } from '@ionic/react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {SimpleProduct} from '../../types/product.type';
import ProductCard from '../Products/ProductCard';
import "./FlashSale.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import CustomCountDown from '../CustomCountDown';
import { appSubDir } from '../../configs/app';

const slideOpts = {
    autoplay: {
        delay: 5000
    },
    // loop: true,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 3,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 6,
            spaceBetween: 10
        }
    }
};
type Props = {
    products?:SimpleProduct[]
}
const FlashSale: React.FC<Props> = ({products}) => {
    if(!products){
        return null
    }
    return (
        <div className='flash-sale mb-50'>
            <section className='title'>
                <IonText color="primary" className='f'>Flash</IonText>
                <FontAwesomeIcon color='#D62447' size="2x" icon={faBolt} />
                <IonText color="danger" className='s'>Sale</IonText>
            </section>
            <CustomCountDown />
            <hr />
            {products && <Swiper {...slideOpts} modules={[Autoplay]}>
                {products.map((item, index) => <SwiperSlide key={index}>
                    <ProductCard product={item} />
                </SwiperSlide>)}
            </Swiper>}
            <div className='text-center' style={{marginTop:20}}>
                <IonButton color='danger' fill='outline' routerLink={appSubDir+"flash-sale"}>
                    View All
                </IonButton>
            </div>
        </div>
    );
};

export default FlashSale;
