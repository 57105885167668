import * as React from "react"
const GooglePlaystoreIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    width={108.378}
    height={96.836}
    viewBox="0 0 108.378 96.836"
    {...props}
  >
    <defs>
      <linearGradient id="a" x1={0.613} x2={0.26} y1={0.956} y2={0.278}>
        <stop offset="0%" stopColor="#00A0FF" />
        <stop offset=".657%" stopColor="#00A1FF" />
        <stop offset="26.01%" stopColor="#00BEFF" />
        <stop offset="51.22%" stopColor="#00D2FF" />
        <stop offset="76.04%" stopColor="#00DFFF" />
        <stop offset="100%" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient id="b" x1={1.076} x2={-1.305} y1={0.509} y2={0.509}>
        <stop offset="0%" stopColor="#FFE000" />
        <stop offset="40.87%" stopColor="#FFBD00" />
        <stop offset="77.54%" stopColor="orange" />
        <stop offset="100%" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient id="c" x1={0.867} x2={-0.505} y1={0.691} y2={-0.361}>
        <stop offset="0%" stopColor="#FF3A44" />
        <stop offset="100%" stopColor="#C31162" />
      </linearGradient>
      <linearGradient id="d" x1={-0.193} x2={0.418} y1={1.119} y2={0.649}>
        <stop offset="0%" stopColor="#32A071" />
        <stop offset="6.85%" stopColor="#2DA771" />
        <stop offset="47.62%" stopColor="#15CF74" />
        <stop offset="80.09%" stopColor="#06E775" />
        <stop offset="100%" stopColor="#00F076" />
      </linearGradient>
      <linearGradient id="e" x1={0.862} x2={-0.501} y1={0.695} y2={-0.021}>
        <stop offset="0%" stopColor="#CC2E36" />
        <stop offset="100%" stopColor="#9C0E4E" />
      </linearGradient>
      <linearGradient id="f" x1={3.073} x2={-0.663} y1={15.318} y2={4.496}>
        <stop offset="0%" stopColor="#008DE0" />
        <stop offset=".657%" stopColor="#008DE0" />
        <stop offset="26.01%" stopColor="#00A7E0" />
        <stop offset="51.22%" stopColor="#00B8E0" />
        <stop offset="76.04%" stopColor="#00C4E0" />
        <stop offset="100%" stopColor="#00C7E0" />
      </linearGradient>
      <linearGradient id="g" x1={1.128} x2={-2.882} y1={1} y2={1}>
        <stop offset="0%" stopColor="#E0C500" />
        <stop offset="40.87%" stopColor="#E0A600" />
        <stop offset="77.54%" stopColor="#E09100" />
        <stop offset="100%" stopColor="#E08900" />
      </linearGradient>
      <linearGradient id="h" x1={1.128} x2={-2.882} y1={0} y2={0}>
        <stop offset="0%" stopColor="#FFE840" />
        <stop offset="40.87%" stopColor="#FFCE40" />
        <stop offset="77.54%" stopColor="#FFBC40" />
        <stop offset="100%" stopColor="#FFB540" />
      </linearGradient>
      <linearGradient id="i" x1={3.061} x2={-0.657} y1={0.234} y2={-10.098}>
        <stop offset="0%" stopColor="#40B8FF" />
        <stop offset=".657%" stopColor="#40B9FF" />
        <stop offset="26.01%" stopColor="#40CEFF" />
        <stop offset="51.22%" stopColor="#40DDFF" />
        <stop offset="76.04%" stopColor="#40E7FF" />
        <stop offset="100%" stopColor="#40EAFF" />
      </linearGradient>
      <linearGradient id="j" x1={-0.188} x2={0.421} y1={0.856} y2={0.537}>
        <stop offset="0%" stopColor="#65B895" />
        <stop offset="6.85%" stopColor="#62BD95" />
        <stop offset="47.62%" stopColor="#50DB97" />
        <stop offset="80.09%" stopColor="#44ED98" />
        <stop offset="100%" stopColor="#40F498" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="l"
        x1={243.89}
        x2={103.763}
        y1={380.413}
        y2={110.924}
        gradientTransform="matrix(.00382 0 0 -.00702 43.084 25.481)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#b"
        id="n"
        x1={219.82}
        x2={-265.185}
        y1={104.456}
        y2={104.456}
        gradientTransform="matrix(.00587 0 0 -.00457 44.603 24.554)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#c"
        id="p"
        x1={279.815}
        x2={-162.26}
        y1={222.803}
        y2={-116.045}
        gradientTransform="matrix(.00604 0 0 -.00444 43.146 25.517)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#d"
        id="r"
        x1={-62.115}
        x2={134.984}
        y1={361.335}
        y2={210.03}
        gradientTransform="matrix(.00604 0 0 -.00444 43.147 24.088)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#e"
        id="t"
        x1={231.994}
        x2={-133.972}
        y1={187.683}
        y2={-4.388}
        gradientTransform="matrix(.00732 0 0 -.00366 43.135 25.538)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#f"
        id="v"
        x1={62.549}
        x2={-13.255}
        y1={311.274}
        y2={91.69}
        gradientTransform="matrix(.00312 0 0 -.0086 43.084 25.493)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#g"
        id="x"
        x1={127.112}
        x2={-322.924}
        y1={112.451}
        y2={112.451}
        gradientTransform="matrix(.00633 0 0 -.00424 45.09 24.561)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#h"
        id="z"
        x1={127.114}
        x2={-322.928}
        y1={0.829}
        y2={0.829}
        gradientTransform="matrix(.00633 0 0 -.00424 45.09 24.088)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#i"
        id="B"
        x1={62.249}
        x2={-13.13}
        y1={4.878}
        y2={-204.589}
        gradientTransform="matrix(.00311 0 0 -.00862 43.084 22.857)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#j"
        id="D"
        x1={-49.894}
        x2={113.528}
        y1={230.7}
        y2={144.927}
        gradientTransform="matrix(.00732 0 0 -.00366 43.135 23.623)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#a"
        id="m"
        x1={243.89}
        x2={103.763}
        y1={380.413}
        y2={110.924}
        gradientTransform="matrix(.01295 0 0 -.01991 39.76 2.249)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#b"
        id="o"
        x1={219.82}
        x2={-265.185}
        y1={104.456}
        y2={104.456}
        gradientTransform="matrix(.01989 0 0 -.01297 44.902 -.385)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#c"
        id="q"
        x1={279.815}
        x2={-162.26}
        y1={222.803}
        y2={-116.045}
        gradientTransform="matrix(.02044 0 0 -.01262 39.967 2.351)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#d"
        id="s"
        x1={-62.115}
        x2={134.984}
        y1={361.335}
        y2={210.03}
        gradientTransform="matrix(.02044 0 0 -.01262 39.971 -1.706)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#e"
        id="u"
        x1={231.994}
        x2={-133.972}
        y1={187.683}
        y2={-4.388}
        gradientTransform="matrix(.0248 0 0 -.0104 39.932 2.409)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#f"
        id="w"
        x1={62.549}
        x2={-13.255}
        y1={311.274}
        y2={91.69}
        gradientTransform="matrix(.01055 0 0 -.02444 39.76 2.28)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#g"
        id="y"
        x1={127.112}
        x2={-322.924}
        y1={112.451}
        y2={112.451}
        gradientTransform="matrix(.02143 0 0 -.01203 46.55 -.363)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#h"
        id="A"
        x1={127.114}
        x2={-322.928}
        y1={0.829}
        y2={0.829}
        gradientTransform="matrix(.02143 0 0 -.01203 46.55 -1.706)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#i"
        id="C"
        x1={62.249}
        x2={-13.13}
        y1={4.878}
        y2={-204.589}
        gradientTransform="matrix(.01054 0 0 -.02447 39.76 -5.202)"
        gradientUnits="userSpaceOnUse"
      />
      <linearGradient
        xlinkHref="#j"
        id="E"
        x1={-49.894}
        x2={113.528}
        y1={230.7}
        y2={144.927}
        gradientTransform="matrix(.0248 0 0 -.0104 39.932 -3.027)"
        gradientUnits="userSpaceOnUse"
      />
      <path id="k" d="M194.463 540.472h398.313V574H194.463z" />
      <path id="F" d="M567.835 2311.088h620.097v120.166H567.835z" />
      <path id="G" d="M562.142 2307.308h653.091v106.83H562.142z" />
    </defs>
    <g
      style={{
        shapeRendering: "crispEdges",
      }}
    >
      <path
        fill="#00c1ff"
        d="M39.975-5.678c-.134.097-.214.258-.214.473v-.049V1.82v-.047c0 .198.069.351.185.45l.03.022a.592.592 0 0 0 .352.104c.134 0 .286-.034.446-.107l5.787-2.637 1.987-.906c.253-.115.395-.263.418-.415-.023-.152-.165-.3-.418-.415l-1.987-.906-5.787-2.637a1.093 1.093 0 0 0-.447-.108.592.592 0 0 0-.352.104z"
        style={{
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#l)"
        d="m39.975 2.245-.03-.022c-.115-.099-.184-.252-.184-.45v.047-7.072.047c0-.215.08-.376.214-.473l4.94 3.962-4.94 3.961"
        style={{
          fill: "url(#m)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#n)"
        d="m46.56-.395-1.646-1.321 1.647-1.321 1.987.906c.253.115.395.263.418.415-.023.151-.165.3-.418.415l-1.987.906"
        style={{
          fill: "url(#o)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#p)"
        d="M40.328 2.35a.592.592 0 0 1-.353-.105l4.94-3.961 1.646 1.32-5.787 2.638a1.09 1.09 0 0 1-.446.107"
        style={{
          fill: "url(#q)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#r)"
        d="m44.914-1.716-4.94-3.962a.592.592 0 0 1 .353-.104c.135 0 .287.035.447.108l5.787 2.637-1.647 1.32"
        style={{
          fill: "url(#s)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#t)"
        d="M40.328 2.396a.593.593 0 0 1-.353-.104.011.01 0 0 0-.001 0l-.026-.02-.002-.003.03-.023a.592.592 0 0 0 .352.103c.134 0 .286-.034.446-.107l5.787-2.637.037.03-.038.017-5.786 2.637c-.146.066-.284.1-.41.107a.751.601 0 0 1-.036 0"
        style={{
          fill: "url(#u)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#v)"
        d="M39.946 2.27c-.116-.1-.185-.252-.185-.45v-.047c0 .198.069.351.185.45l.03.022-.03.024"
        style={{
          fill: "url(#w)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#x)"
        d="m46.598-.365-.037-.03 1.987-.906c.253-.115.395-.264.418-.415 0 .056-.015.111-.046.165-.062.109-.186.212-.372.297l-1.95.889"
        style={{
          fill: "url(#y)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#z)"
        d="M48.966-1.716c-.023-.152-.165-.3-.418-.415l-1.987-.906.037-.03 1.95.89c.279.126.418.293.418.46"
        style={{
          fill: "url(#A)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#B)"
        d="M39.761-5.205v-.05c0-.017 0-.034.002-.05v-.004a.547.547 0 0 1 .183-.392l.029.023c-.134.097-.214.258-.214.473"
        style={{
          fill: "url(#C)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
      <path
        fill="url(#D)"
        d="m46.56-3.037-5.786-2.637a1.093 1.093 0 0 0-.447-.108.592.592 0 0 0-.352.104l-.03-.023a.534.427 0 0 1 .03-.023.591.591 0 0 1 .349-.104h.003c.135 0 .287.034.447.107l5.824 2.654-.037.03"
        style={{
          fill: "url(#E)",
          strokeWidth: 0.0160591,
        }}
        transform="translate(-468.14 68.623) scale(11.77382)"
      />
    </g>
  </svg>
)
export default GooglePlaystoreIcon
