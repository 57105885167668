import { IonButton, IonContent, IonIcon, IonModal } from "@ionic/react";
import { person, personOutline } from "ionicons/icons";
import { useRef } from "react";
import "./AuthModal.scss";
import { useAppContext } from "../../services/app-context.service";
import LoginForm from "./LoginForm";
import UserProfile from "./UserProfile";
import UserMenu from "./UserMenu";
import { baseUrl } from "../../configs/app";
import AuthBody from "./AuthBody";
import { AuthContextProvider, defaultAuthData } from "../../services/auth-context.service";
import { WithdrawDetailsModal } from "../Account/WithdrawDetailsModal";
import { EditProfileModal } from "../Account/EditProfile";
import { ShopNameModal } from "../Account/ShopNameModal";

export let authModalRef: React.RefObject<HTMLIonModalElement>
const AuthModal: React.FC = () => {
    authModalRef = useRef<HTMLIonModalElement>(null)
    const { user } = useAppContext()
    function onClick() {
        defaultAuthData.role_id = 3
        authModalRef.current?.present()
    }
    return <>
        <IonButton fill='clear' onClick={onClick} id="btn-auth-modal" className={user.token && user.photo ? "no-padding" : undefined}>
            {user.token ? (
                user.photo ?
                    <img src={baseUrl + user.photo} alt="" className="cover" /> :
                    <IonIcon size='large' icon={person} />
            ) : <IonIcon size='large' icon={personOutline} />}
        </IonButton>
        <IonModal ref={authModalRef} id="auth-modal">

            {user.token ? <IonContent color="light">
                <div className="row">
                    <div className="col">
                        {/* <h3>Menu</h3> */}
                        <UserMenu />
                    </div>
                    <hr />
                    <div className="col">
                        <h3>My Profile</h3>
                        <UserProfile />
                    </div>
                </div></IonContent> : <AuthContextProvider>
                <AuthBody />
            </AuthContextProvider>}

        </IonModal>
        <WithdrawDetailsModal />
        <EditProfileModal />
        <ShopNameModal />
    </>
};
export default AuthModal
