import { IonButton, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { useEffect, useRef, useState } from 'react';
import { fetchAllBrands, getAllBrands } from '../../services/products/all-brands.service';
import { Brand } from '../../types/product.type';
type Props = {
    title: string
    excludedBrandID?: number
}
const AllBrands: React.FC<Props> = ({ title, excludedBrandID }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    const [items, setItems] = useState(getAllBrands())
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(!items){
            setLoading(true)
        }
        fetchAllBrands().then((items) => {
            setItems(items)
        }).finally(()=>setLoading(false))
    }, [excludedBrandID])
    function filter(items:Brand[]){
        return excludedBrandID ? items.filter((item) => item.id !== excludedBrandID) : items
    }
    if (!items) {
        return null
    }

    return <>
        <IonButton onClick={() => modal.current?.present()} fill='outline'>{title}</IonButton>
        <IonModal ref={modal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{title}</IonTitle>
                    {window.screen.width > 425 && <IonButton onClick={() => modal.current?.dismiss()} slot='end' fill='clear'>Close</IonButton>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading && <div className='box-center'><IonSpinner /></div>}
                {filter(items).map((item, index) => <IonItem key={index} routerLink={appSubDir + "brands/" + item.id}>{item.name}</IonItem>)}
            </IonContent>
            {window.screen.width <= 425 && <IonFooter>
                <IonButton onClick={() => modal.current?.dismiss()} expand='block' fill='clear'>Close</IonButton>
            </IonFooter>}
        </IonModal>
    </>
};

export default AllBrands;
