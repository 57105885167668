import { apiBaseUrl } from "../../configs/app";
import { lockerHandler } from "../../configs/crypto.config";
import Category, { SubCategory } from "../../types/category.type";
import CryptoService from "../crypto.services";

const localStorageKey = CryptoService.hash('categories-and-subcategories')
export function localCategories() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)).categories : []) as Category[]
}
export function getCategory(categories:Category[],catID:number) {
    return categories.find((item, index) => (catID === item.id))
}
export function getSubCategory(items:SubCategory[],subCatID:number) {
    return items.find((item, index) => (subCatID === item.id))
}
export function fetchCategories() {
    const requestOptions = {
        method: 'POST',
        cache: "no-store",
        redirect: 'follow'
    };
    const url = apiBaseUrl + 'products/categories-and-subcategories'
    return fetch(url, requestOptions as RequestInit).then((res) => res.json())
        .then((res) => {
            if (res.success) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res.categories as Category[]
            } else {
                return [] as Category[]
            }
        })
}
