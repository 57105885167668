import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';

import styles from "./CartBody.module.scss";
import CartProduct from './CartProduct';
import { useAppContext } from '../../services/app-context.service';
const CartBody: React.FC = () => {
    const { cart } = useAppContext()
    return (
        <>
            <IonGrid>
                {cart.itemCount < 1 &&
                    <IonRow className={styles.emptyCartContainer}>
                        <IonCol size="10" className="ion-text-center">
                            <div className={styles.text}>
                                <img src="/assets/img/cart.webp" alt="no cart" />
                                <h1>Hang on there!</h1>
                                <p>Your cart is empty</p>
                                <IonButton color="primary" routerLink="/shop">Shop now &rarr;</IonButton>
                            </div>
                        </IonCol>
                    </IonRow>
                }
                {cart.itemCount > 0 &&
                    <>
                        <IonRow>
                            {cart.items.map((item, index) => {
                                return <CartProduct itemIndex={index} cartItem={item} key={index} />;
                            })}
                        </IonRow>
                    </>
                }
            </IonGrid>
        </>
    );
};

export default CartBody;
