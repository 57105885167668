import { apiBaseUrl } from "../../configs/app";
import { SimpleInvoice } from "../../types/shop/invoice.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
const localStorageKey = CryptoService.hash("active-orders")
export function getOrders() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as SimpleInvoice[] : [] as SimpleInvoice[])
}
export function fetchOrders() {
    return loginService.privateGetApiCall(apiBaseUrl + "active-invoices").then((res) => {
        const ApiResponse = res.data as SimpleInvoice[]
        window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(ApiResponse)))
        return ApiResponse
    })
}
