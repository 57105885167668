import LoginForm from "./LoginForm";
import { useAuthContext } from "../../services/auth-context.service";
import RegistrationForm from "./SignUp/Form";
import RegistrationOtp from "./SignUp/Otp";
import ForgotPassForm from "./ForgotPass/Form";
import ForgotPassOtp from "./ForgotPass/Otp";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { recaptchaSiteKey } from "../../configs/app";

const AuthBody: React.FC = () => {
    const { screen } = useAuthContext()
    return <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
        {screen === 'login' && <LoginForm />}
        {screen === 'registration' && <RegistrationForm />}
        {screen === 'otp' && <RegistrationOtp />}
        {screen === 'forgot-pass-phone' && <ForgotPassForm />}
        {screen === 'forgot-pass-otp' && <ForgotPassOtp />}
    </GoogleReCaptchaProvider>
};
export default AuthBody
