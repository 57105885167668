import {
    IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel,
    IonNote, IonSpinner, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import Footer from '../Footer';
import { useAuthContext, useAuthContextUpdater } from '../../../services/auth-context.service';
import { requestOTP } from '../../../functions/forgor-pass.service';
import { recaptchaSiteKey } from '../../../configs/app';

type FormData = {
    phone: string
}

const ForgotPassForm: React.FC = () => {
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
    const [presentToast] = useIonToast();
    function onUpdateReCaptcha(token: any) {
        if (token) {
            setReCaptchaToken(token)
        } else {
            setReCaptchaToken(null)
        }
    }
    function validator(values: FormData) {
        const errors = {} as FormData;

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (
            values.phone.length != 11
        ) {
            errors.phone = '11 Digit Required';
        } else if (
            !values.phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.phone = 'Invalid Phone Number';
        }
        return errors;
    }
    function onSubmit(values: FormData, formikHelpers: FormikHelpers<FormData>) {
        if (!reCaptchaToken) {
            console.error("ReCaptcha Token Was not set")
            return
        }
        const { setSubmitting } = formikHelpers

        setSubmitting(true);
        requestOTP(values.phone, reCaptchaToken).then((res) => {
            setAuthData({
                ...authData,
                forgotPassPayload: res.data.payload,
                forgotPassPhone: values.phone,
                screen: 'forgot-pass-otp'
            })
        }).catch(err => {
            presentToast({
                message: err.response.data.message ? err.response.data.message : "Something Went Wrong",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
            setReCaptchaToken(null)
        })
    }
    return (
        <>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Reset Password</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <div style={{ maxWidth: 300, margin: "0 auto" }}>
                    <Formik
                        initialValues={{
                            // name: "Custoemr One",
                            // phone: "01660000001",
                            // email: "cus.1@bbazar.test",
                            // password: "12345678",
                            // confirmPassword: "12345678"
                        } as FormData}
                        validate={validator}
                        onSubmit={onSubmit}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit} style={{ marginTop: 50 }}>
                                <IonItem className={errors.phone && touched.phone ? 'ion-invalid' : undefined}>
                                    <IonLabel position="floating">Phone</IonLabel>
                                    <IonInput name='phone' placeholder='01XXXXXXXXX (BD Mobile Number Only)' min={1000000000} max={1999999999}
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone} />
                                    {errors.phone && touched.phone && <IonNote slot='error'>{errors.phone}</IonNote>}
                                </IonItem>
                                {!!reCaptchaToken && <IonButton disabled={isSubmitting} type='submit' expand="block" color="primary">{
                                    isSubmitting ?
                                        <IonSpinner /> :
                                        "ফোনে OTP পাঠান"
                                }</IonButton>}
                            </form>
                        )}
                    </Formik>
                    {!reCaptchaToken && <div style={{ display: "flex", justifyContent: "center", padding: '10px 0 0' }}>
                        <GoogleReCaptcha
                            onVerify={onUpdateReCaptcha}
                        />
                    </div>}
                </div>
            </IonContent>
            <Footer />
        </>
    );
};

export default ForgotPassForm;
