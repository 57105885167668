import { IonButton, IonCol, IonIcon, IonItem, IonLabel, IonNote, IonRow, IonText } from "@ionic/react";
import { useAppContext } from "../../services/app-context.service";
import { flower, mail, person, phoneLandscape } from "ionicons/icons";
import "./UserProfile.scss";
import { baseUrl } from "../../configs/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { EditProfileButton } from "../Account/EditProfile";

const UserProfile: React.FC = () => {
    const { user } = useAppContext()
    return <>
        <div id='profile'>
            <div className="cover">
                <img src={baseUrl + user.photo} alt="" />
                <IonButton fill="clear">
                    <FontAwesomeIcon icon={faEdit} />
                </IonButton>
            </div>
            <div className="info">
                <IonItem >
                    <div slot="start" className="label">
                        <IonIcon icon={person} />
                        <IonLabel>Name</IonLabel>
                    </div>
                    <IonText>{user.name}</IonText>
                </IonItem>
                <IonItem >
                    <div slot="start" className="label">
                        <IonIcon icon={flower} />
                        <IonLabel>Account</IonLabel>
                    </div>
                    <IonText>{user.role_id == 2 ? "Reseller" : "Customer"}</IonText>
                </IonItem>
                <IonItem >
                    <div slot="start" className="label">
                        <IonIcon icon={mail} />
                        <IonLabel>Email</IonLabel>
                    </div>
                    <IonText>{user.email}</IonText>
                </IonItem>
                {user.phone ? <>
                    <IonItem >
                        <div slot="start" className="label">
                            <IonIcon icon={phoneLandscape} />
                            <IonLabel>Phone</IonLabel>
                        </div>
                        <IonText>{user.phone}</IonText>
                    </IonItem>
                    <IonItem >
                        <IonNote color="danger">Make sure you use "{user.phone}". If you don't, then update the phone number. Otherwise You may lose your account.</IonNote>
                    </IonItem>
                </> : <IonItem >
                    <IonNote color="danger">Please, Update Phone Number. Otherwise You may lose your account.</IonNote>
                </IonItem>}
                <EditProfileButton/>
            </div>
        </div>
    </>
};
export default UserProfile
