import { apiBaseUrl } from "../configs/app";
import User from "../types/user.type";

export class UserClass{
    user = {} as User
    constructor(user:User){
        this.user = user
    }
    saveLocal(){
        window.localStorage.setItem('user',JSON.stringify(this.user))
    }
    fetchFromOnline(){
        if(!this.user.token){
            return null
        }
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+ this.user.token);

        const requestOptions = {
            method: 'GET',
            cache: "no-store",
            headers: myHeaders,
            redirect: 'follow'
        };
        return fetch(apiBaseUrl+"details",requestOptions as RequestInit).then((res)=>res.json())
        .then((user:any)=>{
            this.user = {...this.user,...user}
            this.saveLocal()
            return this.user
        })
    }
}
