import { IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { getAllTransactions, fetchAllTransactions } from '../../services/account/transaction.service';
import { Link } from 'react-router-dom';

const BDT = new Intl.NumberFormat('en-IN');
const TransactionsPage: React.FC = () => {
    const [loading, setLoadling] = useState(false)
    const response = getAllTransactions()
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [items, setItems] = useState(response ? response.data : null)
    useEffect(() => {
        loadData(1)
    }, [])
    function loadData(page: number) {
        const local = getAllTransactions(page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchAllTransactions(page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>No Transaction Found</h1>
                        </div>}
                        {items && <div className='page-container'>
                            <div className='title-bar' style={{ marginTop: 60, marginBottom: 50 }}>
                                <h1>Reseller Transaction History</h1>
                            </div>
                            <div style={{ marginTop: 30, overflow: 'auto' }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Opening</th>
                                            <th>Commission</th>
                                            <th>Promo Commission</th>
                                            <th>Delivery Cost</th>
                                            <th>Withdrawn</th>
                                            <th>Closing</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                            return <tr style={{ cursor: "pointer" }} key={index}>
                                                <td className='text-center'><Link to={appSubDir + "transactions/" + item.date}>{item.date}</Link></td>
                                                <td className='text-end'>{BDT.format(item.opening)}/=</td>
                                                <td className='text-end'>{BDT.format(item.sale_profit)}/=</td>
                                                <td className='text-end'>{BDT.format(item.promo_profit)}/=</td>
                                                <td className='text-end'>{BDT.format(item.return_cost)}/=</td>
                                                <td className='text-end'>{BDT.format(item.withdrawn)}/=</td>
                                                <td className='text-end'>{BDT.format(item.closing)}/=</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadData} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default TransactionsPage;
